// DLO-1007
import {
  faArrowDown,
  faArrowLeft,
  faArrowRightFromBracket,
  faArrowUp,
  faBars,
  faBarsProgress,
  faBell,
  faBook,
  faBookOpen,
  faBurst,
  faCalendarDays,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleExclamation,
  faCircleInfo,
  faCode,
  faComment,
  faCreditCard,
  faCubes,
  faDiamond,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faExclamation,
  faFile,
  faFileContract,
  faFolder,
  faGear,
  faHandPointRight,
  faHandPointUp,
  faHighlighter,
  faImage,
  faInbox,
  faLanguage,
  faLeftLong,
  faLightbulb,
  faLocationDot,
  faLock,
  faMagnifyingGlass,
  faMaximize,
  faMinimize,
  faMobile,
  faNewspaper,
  faNoteSticky,
  faPalette,
  faPaperPlane,
  faPaste,
  faPenNib,
  faPencil,
  faPlay,
  faPlus,
  faQrcode,
  faQuestion,
  faRightLong,
  faSheetPlastic,
  faSitemap,
  faSpinner,
  faTable,
  faTextHeight,
  faTrash,
  faTriangleExclamation,
  faUpDown,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserPlus,
  faVideo,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import {
  faBloggerB,
  faLinkedin,
  faMeetup,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
export const fontAwesomeIcons = {
  faYoutube,
  faChevronLeft,
  faArrowUp,
  faHighlighter,
  faArrowDown,
  faQuestion,
  faFileContract,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faEllipsisVertical,
  faBars,
  faExclamation,
  faBell,
  faQrcode,
  faGear,
  faCircle,
  faPaste,
  faX,
  faBurst,
  faInbox,
  faMaximize,
  faMinimize,
  faSitemap,
  faVideo,
  faHandPointUp,
  faPlay,
  faArrowRightFromBracket,
  faNoteSticky,
  faDiamond,
  faNewspaper,
  faTriangleExclamation,
  faMagnifyingGlass,
  faPaperPlane,
  faDownload,
  faSpinner,
  faTextHeight,
  faComment,
  faTable,
  faCode,
  faFile,
  faPalette,
  faMobile,
  faLocationDot,
  faEnvelope,
  faCheck,
  faUserPlus,
  faPencil,
  faArrowLeft,
  faBookOpen,
  faUpDown,
  faUser,
  faImage,
  faPlus,
  faPenNib,
  faLanguage,
  faFolder,
  faBarsProgress,
  faLock,
  faCreditCard,
  faSheetPlastic,
  faCalendarDays,
  faHandPointRight,
  faLightbulb,
  faChartLine,
  faCircleExclamation,
  faTrash,
  faCircleInfo,
  faLinkedin,
  faMeetup,
  faCubes,
  faBook,
  faBloggerB,
  faLeftLong,
  faRightLong,
  faUpRightAndDownLeftFromCenter,
};
