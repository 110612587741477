import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useCheckAccessAndRedirect from "../../hooks/useCheckAccessAndRedirect";
import "../../sass/Contents/HeaderLogin/Configuration.scss";
import useModalStore from "../../Store/useModalStore";
import useUserProfile from "../../Store/useUserProfile";
import { checkAccess } from "../../Utils/accessUtils";
import { useAuthenticationActions } from "../../Utils/useAuthenticationActions";

function Configuration() {
  const checkAccessAndRedirect = useCheckAccessAndRedirect();
  let history = useHistory();
  const { t } = useTranslation(["HeaderLogin", "translation"]);
  const { accounts } = useMsal();
  const {
    openConfiguration,
    setSeeBadVersion,
    setSeeLimitedOfferM,
    setSeeEarlyAdopterM,
  } = useModalStore();

  const setSeeNewFeature = useModalStore((s) => s.setSeeNewFeature);

  const { userData, AccessType } = useUserProfile();

  const { handleLogoutPopup } = useAuthenticationActions();

  const accountData = accounts[0]?.idTokenClaims || userData[0]?.idTokenClaims;
  if (!accountData) return null;
  if (!openConfiguration) return null;

  const handleCheckAccessRedirectClick = () => {
    checkAccessAndRedirect();
  };

  function Disconnect() {
    history.push("/");
    handleLogoutPopup();
  }
  let accesEnv = ["TEST", "DEV"];
  return (
    <div className="Configuration">
      <button onClick={() => history.push("/video-demonstration")}>
        <FontAwesomeIcon className="icon" icon=" fa-solid fa-paste" />
        <span>{t("Configuration.userGuide")}</span>
      </button>
      {checkAccess(AccessType) ? (
        <>
          <button onClick={handleCheckAccessRedirectClick}>
            <FontAwesomeIcon className="icon" icon="fa-solid fa-qrcode" />
            <span>{t("translation:ButtonsConnect.docScript")}</span>
          </button>
          <button
            onClick={() => {
              history.push("/homeLogin-confidia");
              setSeeNewFeature(true);
            }}
          >
            <FontAwesomeIcon className="icon" icon="fa-solid fa-circle" />
            <span>{t("Configuration.offer")}</span>
          </button>
        </>
      ) : (
        <button onClick={() => setSeeLimitedOfferM(true)}>
          <FontAwesomeIcon
            className="icon"
            icon="fa-solid fa-hand-point-right"
          />
          <span>{t("translation:ButtonsConnect.subscrib")}</span>
        </button>
      )}
      <button
        onClick={() => {
          history.push("/advantages");
        }}
      >
        <FontAwesomeIcon className="icon" icon="fa-solid fa-circle-info" />
        <span>{t("translation:ButtonsGlobals.Advantages")}</span>
      </button>

      <button
        onClick={() => {
          history.push("/community/expositions");
        }}
      >
        <FontAwesomeIcon className="icon" icon="fa-solid fa-burst" />
        <span>{t("translation:ButtonsGlobals.expositions")}</span>
      </button>

      {checkAccess(AccessType) && (
        <button
          onClick={() => {
            history.push("/homeLogin-confidia");
            setSeeNewFeature(true);
          }}
        >
          <FontAwesomeIcon className="icon" icon="fa-solid fa-gear" />
          <span>{t("Configuration.setting")}</span>
        </button>
      )}
      {accesEnv.includes(process.env.REACT_APP_ENVNAME) && (
        <button>
          <FontAwesomeIcon className="icon" icon="fa-solid fa-file-contract" />
          <span onClick={() => history.push("/home-quote")}>
            Gestion des devis
          </span>
        </button>
      )}
      <button onClick={Disconnect}>
        <FontAwesomeIcon
          className="icon"
          icon="fa-solid fa-arrow-right-from-bracket"
        />
        <span>{t("Configuration.disconnect")}</span>
      </button>
    </div>
  );
}

export default Configuration;
