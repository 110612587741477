import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Mettre à jour l'état pour que le prochain rendu ne montre pas d'UI de fallback
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Logger l'erreur sur un service externe ou console
    console.error("Error caught by Error Boundary:", error, errorInfo);
    // Mettre à jour l'état avec les détails de l'erreur sans interrompre l'UI
    this.setState({ error: error, errorInfo: errorInfo });
  }

  render() {
    // Ne pas changer l'UI, juste retourner les enfants pour que l'app continue de fonctionner
    return this.props.children;
  }
}

export default ErrorBoundary;
